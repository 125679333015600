<template>
	<div class="admin-list">
		<CustomTableTopic
			:total-items="totalItems"
			:total-pages="totalPages"
			:recent-filter="recentFilters"
			:type="tableType"
			@search="search"
			@trash="trashListTopicComment"
			@unTrash="trashListTopicComment"
		>
			{{ selected }}
			<template #headers>
				<tr>
					<th class="fit">
						<label class="form-checkbox">
							<input v-model="selectAll" type="checkbox" @click="select" />
							<i class="form-icon"></i>
						</label>
					</th>
					<th
						v-for="(header, index1) in headers"
						:key="`${header.title}-${index1}`"
						scope="col"
						:class="`${header.class}`"
						>{{ header.title }}
					</th>
				</tr>
			</template>
			<template #body>
				<template v-if="topics && topics.length">
					<tr v-for="(topic, index2) in topics" :key="`${topic.cId}-${index2}`">
						<td class="fit">
							<label class="form-checkbox">
								<input v-model="selected" type="checkbox" :value="topic.id" />
								<i class="form-icon"></i>
							</label>
						</td>
						<td v-if="topic.isComment" scope="row" class="text-break text-pre-wrap content"
							>{{ topic.cBody }}
						</td>
						<td v-else scope="row" class="text-break text-pre-wrap content">
							<div> {{ topic.tTitle }}</div>
							{{ topic.tBody }}
						</td>
						<td scope="row" class="text-break text-no-wrap type"
							>{{ getType(topic.isComment) }}
							<div>{{ getCreateTime(topic) }} </div>
							<div class="d-flex flex-row">
								<a class="link-icon link-redirect" target="_blank" :href="getDeepLink(topic)">
									Link
								</a>
							</div>
						</td>
						<td scope="row" class="text-break text-pre-wrap name">
							<router-link
								:to="{
									name: 'CustomerDetail',
									params: { id: getUserId(topic) },
								}"
								target="_blank"
								class="link-icon link-redirect"
								>{{ getUsername(topic) }}
							</router-link>
							<div>Nick: {{ getNickName(topic) }}</div>
						</td>
						<td scope="row" class="text-break category">
							<multiselect
								v-if="!topic.isComment"
								v-model="topic.categoryId"
								:options="categories"
								:custom-label="labelCategory"
								:searchable="false"
								:close-on-select="true"
								:show-labels="false"
								placeholder=""
								@input="onCategoryChange($event, topic.tId)"
							>
							</multiselect>
							<multiselect
								v-else
								v-model="topic.categoryId"
								:options="categories"
								:custom-label="labelCategory"
								:searchable="false"
								:close-on-select="true"
								:show-labels="false"
								placeholder=""
								:disabled="true"
							>
							</multiselect>
						</td>

						<td scope="row" class="text-break text-pre-wrap question">
							<div v-if="topic.isComment">
								<div>{{ topic.tTitle }}</div>
								{{ topic.tBody }}
							</div>
						</td>
					</tr>
				</template>
				<template v-else>
					<tr class="text-center">
						<td colspan="12" class="fit">
							{{ $t("Table.NoData") }}
						</td>
					</tr>
				</template>
			</template>
		</CustomTableTopic>
	</div>
</template>

<script>
import { USER_ROLE, ADMIN_STATUS, MANAGEMENT, CATEGORY } from "@/shared/plugins/constants"
import {
	CHANGE_TOPIC_CATEGORY,
	GET_LIST_TOPIC_COMMENT,
	TRASH_LIST_TOPIC_COMMENT,
} from "../store/action-types"
import { RESET_FILTERS_TOPIC_LIST } from "../store/mutation-types"
import { createNamespacedHelpers, mapState } from "vuex"

const { mapActions, mapMutations } = createNamespacedHelpers("topics")
import { get } from "lodash-es"
import moment from "moment"

export default {
	name: "AdminTopicManagement",

	components: {},

	props: {},

	data() {
		return {
			selected: [],
			selectAll: false,
			tableType: MANAGEMENT.ADMIN,
			isMessageTab: true,
			currentParam: {},
			headers: [
				{
					title: this.$t("TopicHeader.TopicContent"),
					class: "content",
				},
				{
					title: this.$t("TopicHeader.Type"),
					class: "text-nowrap",
				},
				{
					title: this.$t("TopicHeader.Customer"),
					class: "text-nowrap name",
				},
				{
					title: this.$t("TopicHeader.Category"),
					class: "text-nowrap category",
				},
				{
					title: this.$t("TopicHeader.Question"),
					class: "text-nowrap question",
				},
			],
			selectedCategory: CATEGORY.HIV,
			categories: [
				CATEGORY.HIV,
				CATEGORY.PREP,
				CATEGORY.ART,
				CATEGORY.FRIEND,
				CATEGORY.BLOG,
				CATEGORY.OTHER,
				CATEGORY.COVID,
			],
			OpenIndicator: {
				render: createElement => createElement("span", { class: { toggle: false } }),
			},
		}
	},

	computed: {
		...mapState("topics", ["topics", "totalItems", "totalPages", "recentFilters"]),
		...mapState("authentication", ["currentUser"]),
	},

	watch: {},

	created() {},

	beforeDestroy() {
		if (!this.$route.path.includes("topics")) this.RESET_FILTERS_TOPIC_LIST()
	},

	methods: {
		get,

		...mapActions({ GET_LIST_TOPIC_COMMENT, TRASH_LIST_TOPIC_COMMENT, CHANGE_TOPIC_CATEGORY }),
		...mapMutations({ RESET_FILTERS_TOPIC_LIST }),

		select() {
			this.selected = []
			if (!this.selectAll) {
				for (let i in this.topics) {
					this.selected.push(this.topics[i].id)
				}
			}
		},

		search(params) {
			this.selected = []
			this.selectAll = false
			this.currentParam = params
			this.GET_LIST_TOPIC_COMMENT(params)
		},

		trashListTopicComment(value) {
			let params = {
				trash: value.trash,
				list: [],
			}
			this.topics.forEach(topic => {
				if (this.selected.includes(topic.id)) {
					params.list.push({
						isComment: topic.isComment,
						id: topic.isComment ? topic.cId : topic.tId,
					})
				}
			})
			this.TRASH_LIST_TOPIC_COMMENT(params).then(() => {
				this.search(this.currentParam)
			})
		},

		getUsername(topic) {
			if (topic.isComment) return topic.cName
			return topic.tName
		},
		getNickName(topic) {
			if (topic.isComment) return topic.cNickname
			return topic.tNickname
		},
		getUserId(topic) {
			if (topic.isComment) return topic.cUserId
			return topic.tUserId
		},
		getType(isComment) {
			if (isComment) return this.$t("TopicType.ANSWER")
			return this.$t("TopicType.QUESTION")
		},
		getCreateTime(topic) {
			if (topic.isComment) {
				return moment(topic.cCreatedAt).format("YYYY-MM-DD")
			} else {
				return moment(topic.tCreatedAt).format("YYYY-MM-DD")
			}
		},
		isAuthorized(admin) {
			if (
				this.currentUser?.role === USER_ROLE.SYSTEM_CLINIC &&
				admin.creator?.role === USER_ROLE.SYSTEM_ADMIN
			)
				return false
			return true
		},

		goToAdmin(event, adminId) {
			if (event.target.localName === "td") {
				this.$router.push({ name: "UpdateAdmin", params: { id: adminId } })
			}
		},

		goToAdminNewTab(event, adminId) {
			if (event.target.localName === "td") {
				let routeData = this.$router.resolve({
					name: "UpdateAdmin",
					params: { id: adminId },
				})
				window.open(routeData.href, "_blank")
			}
		},

		getStatusClass(status) {
			return {
				[ADMIN_STATUS.ACTIVE]: "status--active",
				[ADMIN_STATUS.BANNED]: "status--inactive",
			}[status]
		},

		getNo(no) {
			return no + 1 + (+this.recentFilters.offset || 0)
		},
		labelCategory(value) {
			return {
				[CATEGORY.HIV]: this.$t("CATEGORY.HIV"),
				[CATEGORY.PREP]: this.$t("CATEGORY.PREP"),
				[CATEGORY.ART]: this.$t("CATEGORY.ART"),
				[CATEGORY.OTHER]: this.$t("CATEGORY.OTHER"),
				[CATEGORY.FRIEND]: this.$t("CATEGORY.FRIEND"),
				[CATEGORY.BLOG]: this.$t("CATEGORY.BLOG"),
				[CATEGORY.COVID]: this.$t("CATEGORY.COVID"),
			}[value]
		},
		onCategoryChange(value, data) {
			const params = {
				id: data,
				categoryId: value,
			}
			this.CHANGE_TOPIC_CATEGORY(params).then(() => {
				this.search(this.currentParam)
			})
		},
		getDeepLink(topic) {
			let baseUrl = window.location.origin
			let link = baseUrl + "/ref?type=topic&id=" + topic.tId
			return link
		},
	},
}
</script>

<style lang="scss" scoped>
@import "@/assets/scss/variables";
@import "@/assets/scss/mixins";

.admin-list {
	table {
		//.name {
		//	min-width: 40px;
		//	max-width: 80px;
		//}
		//.content {
		//	min-width: 70px;
		//	max-width: 150px;
		//}
		//.type {
		//	min-width: 40px;
		//	max-width: 80px;
		//}
		//.time {
		//	min-width: 40px;
		//	max-width: 80px;
		//}
		//.category {
		//	min-width: 60px;
		//	max-width: 80px;
		//}
		//.question {
		//	min-width: 40px;
		//	max-width: 80px;
		//}

		// .name,
		// .email,
		// .clinic {
		// 	white-space: nowrap !important;
		// 	overflow: hidden;
		// 	text-overflow: ellipsis;
		// }
	}

	//tbody tr:hover {
	//	cursor: pointer;
	//}
}

.status {
	&--active {
		color: $color-sea-green;
	}

	&--inactive {
		color: $color-red;
	}
}
</style>
